<template>
    <ODataLookup :data-object="dsLookupDataObject" :disableRecent="true">
        <template #toolbarActions>
            <slot name="toolbarActions"></slot>
        </template>

        <template #target="scope">
            <slot v-if="$slots.target" name="target" v-bind="scope"></slot>
            <span v-else-if="$slots.slotname" :ref="scope.target" style="cursor: pointer;">
                <slot name="slotname"></slot>
            </span>
        </template>      
        
        <OColumn field="ID" width="80"></OColumn>
        <OColumn field="Name" width="200"></OColumn> 
        <OColumn field="Description" width="350"></OColumn> 
    </ODataLookup>
</template>

<script setup>
    // import { computed, ref, watch, watchEffect } from 'vue';
    import { ref, defineProps, computed } from 'vue';
    import { ODataLookup } from 'o365-datalookup';

    const props = defineProps({
        is: String,   
        textInputValue: String,
        textInput: Boolean
    });

    const dsLookupDataObject = $getDataObjectById("dsAssets_AssetConditionLoopup");
    // const dsLookupDataObject = $getDataObjectById({
        // id: 'dsAssteConditionLookup' + crypto.randomUUID(),
        // viewName: 'atbv_Assets_ConditionLevels',
        // maxRecords: 25,
        // whereClause: "",
        // loadRecents: false,
        // distinctRows: true,
        // fields:
             // [{name: "ID", type: "string" },
              // {name: "Name", type: "string", sortOrder: 1, sortDirection: "asc" },
              // {name: "Description", type: "string" }]
    // });


</script>